import { Module, ActionContext } from 'vuex';
import { ethers } from 'ethers';
import { functions, db, firebase } from '@/firebase';
import Vue from 'vue';
import { State, BlockchainStateSlice, InitialStateSlice } from '@/store/models';
import axios from 'axios';
import to from 'await-to-js';
import detectEthereumProvider from '@metamask/detect-provider';
import * as MUTATIONS from '@/store/constants';
import assetHandlerContractABI from '../../contracts/assetHandlerContract.json';

const trackBlockchainTransactionError = (transactionName, errorMessage, investorId, transactionHash = ''): void => {
  const dateNowTimestamp = firebase.firestore.Timestamp.now();
  const dateNow = dateNowTimestamp.toDate();
  dateNow.setFullYear(dateNow.getFullYear() + 1);
  const expiresAt = firebase.firestore.Timestamp.fromDate(dateNow);

  const errorDocREf = db.collection('blockchainTransactionsErrors').doc();
  errorDocREf.set({
    transactionName,
    transactionHash,
    errorMessage,
    investorId,
    createdDateTime: dateNowTimestamp,
    updatedDateTime: dateNowTimestamp,
    expiresAt,
  });
};

export default <Module<BlockchainStateSlice, State>>{
  state: new InitialStateSlice(),
  mutations: {
    [MUTATIONS.UPDATE_IS_METAMASK_CONNECTED_SUCCESS](state: BlockchainStateSlice, payload: any): void {
      Vue.set(state, 'isMetamaskAccountConnected', payload.isConnected);
      Vue.set(state, 'operations', { status: 'success', name: MUTATIONS.UPDATE_IS_METAMASK_CONNECTED_SUCCESS, payload });
    },
    [MUTATIONS.UPDATE_IS_METAMASK_CONNECTED_PROCESSING](state: BlockchainStateSlice): void {
      Vue.set(state, 'operations', { status: 'processing', name: MUTATIONS.UPDATE_IS_METAMASK_CONNECTED_PROCESSING });
    },
    [MUTATIONS.UPDATE_IS_METAMASK_CONNECTED_ERROR](state: BlockchainStateSlice, error: string): void {
      Vue.set(state, 'operations', { status: 'error', name: MUTATIONS.UPDATE_IS_METAMASK_CONNECTED_ERROR, error });
    },
    [MUTATIONS.UPDATE_BLOCKCHAINTOKENS_PROCESSING](state: BlockchainStateSlice): void {
      Vue.set(state, 'operations', { status: 'processing', name: MUTATIONS.UPDATE_BLOCKCHAINTOKENS_PROCESSING });
    },
    [MUTATIONS.UPDATE_BLOCKCHAINTOKENS_ERROR](state: BlockchainStateSlice, error: string): void {
      Vue.set(state, 'operations', { status: 'error', name: MUTATIONS.UPDATE_BLOCKCHAINTOKENS_ERROR, error });
    },
    [MUTATIONS.UPDATE_BLOCKCHAINTOKENS_SUCCESS](state: BlockchainStateSlice, payload: any): void {
      Vue.set(state, 'blockchainTokens', { tokenName: payload.tokenName, tokensAmount: payload.tokensAmount });
      Vue.set(state, 'operations', { status: 'success', name: MUTATIONS.UPDATE_BLOCKCHAINTOKENS_SUCCESS, payload });
    },
    [MUTATIONS.UPDATE_BLOCKCHAINTOKENS_PROCESSING](state: BlockchainStateSlice): void {
      Vue.set(state, 'operations', { status: 'processing', name: MUTATIONS.UPDATE_BLOCKCHAINTOKENS_PROCESSING });
    },
    [MUTATIONS.UPDATE_BLOCKCHAINTOKENS_ERROR](state: BlockchainStateSlice, error: string): void {
      Vue.set(state, 'operations', { status: 'error', name: MUTATIONS.UPDATE_BLOCKCHAINTOKENS_ERROR, error });
    },
    [MUTATIONS.UPDATE_BLOCKCHAINTOKENS_SUCCESS](state: BlockchainStateSlice, payload: any): void {
      Vue.set(state, 'blockchainTokens', { tokenName: payload.tokenName, tokensAmount: payload.tokensAmount });
      Vue.set(state, 'operations', { status: 'success', name: MUTATIONS.UPDATE_BLOCKCHAINTOKENS_SUCCESS, payload });
    },
    [MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_PROCESSING](state: BlockchainStateSlice, payload): void {
      Vue.set(state, 'operations', { status: 'processing', name: MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_PROCESSING, payload });
    },
    [MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_ERROR](state: BlockchainStateSlice, error: string): void {
      Vue.set(state, 'operations', { status: 'error', name: MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_ERROR, error });
    },
    [MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_SUCCESS](state: BlockchainStateSlice, payload: any): void {
      Vue.set(state, 'operations', { status: 'success', name: MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_SUCCESS, payload });
    },
  },
  actions: {
    async syncBlockchainTokens(
      { commit }: ActionContext<BlockchainStateSlice, State>,
      { investorId }: { investorId: string},
    ): Promise<void> {
      commit(MUTATIONS.UPDATE_BLOCKCHAINTOKENS_PROCESSING);

      try {
        const providerDetected = await detectEthereumProvider({ mustBeMetaMask: true });
        if (providerDetected) {
          // @ts-ignore
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const signerAddress = await signer.getAddress();
          const options = {
            method: 'GET',
            url: `https://deep-index.moralis.io/api/v2/${signerAddress}/nft`,
            params: { chain: 'mumbai', format: 'decimal' },
            headers: { accept: 'application/json', 'X-API-Key': process.env.VUE_APP_MORALIS_API_KEY },
          };

          // @ts-ignore
          axios.request(options)
            .then((response): void => {
              let blockchainData: object | null = null;
              if (response.data.total > 0) {
                blockchainData = {
                  tokenName: response.data.result[0].symbol,
                  tokensAmount: response.data.total,
                };
              }
              commit(MUTATIONS.UPDATE_BLOCKCHAINTOKENS_SUCCESS, blockchainData);
            })
            .catch((error): void => {
              trackBlockchainTransactionError(
                'syncBlockchainTokens',
                error.message || 'Failed to retrieve blockchain tokens data from the api',
                investorId,
              );
              commit(MUTATIONS.UPDATE_BLOCKCHAINTOKENS_ERROR, error.message);
            });
        } else {
          commit(MUTATIONS.UPDATE_BLOCKCHAINTOKENS_ERROR, 'Make sure you have metamask extension installed on your browser.');
        }
      } catch (e) {
        trackBlockchainTransactionError(
          'syncBlockchainTokens',
          'Something went wrong.',
          investorId,
        );
        commit(MUTATIONS.UPDATE_BLOCKCHAINTOKENS_ERROR, 'Something went wrong.');
      }
    },
    updateIsMetamaskAccountConnect(
      { commit }: ActionContext<BlockchainStateSlice, State>,
      { isConnected }: { isConnected: boolean },
    ): void {
      commit(MUTATIONS.UPDATE_IS_METAMASK_CONNECTED_PROCESSING);
      commit(MUTATIONS.UPDATE_IS_METAMASK_CONNECTED_SUCCESS, { isConnected });
    },
    async claimBlockchainTokens(
      { commit }: ActionContext<BlockchainStateSlice, State>,
      { paymentId, tokensAlreadyClaimed, blockchainTokensIds, investmentId, investorId }:
      { paymentId: string, tokensAlreadyClaimed: number, blockchainTokensIds: string[], investmentId: string, investorId: string },
    ): Promise<void> {
      commit(MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_PROCESSING);

      try {
        const providerDetected = await detectEthereumProvider({ mustBeMetaMask: true });
        if (providerDetected) {
          commit(MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_PROCESSING, { paymentId });
          // @ts-ignore
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const investorAddress = await signer.getAddress();
          const proxyContractAddress = '0x10B45FD048d5606a243EDbDBD1DbF9A70BCf889a';
          const assetHandler = new ethers.Contract(proxyContractAddress, assetHandlerContractABI, signer);

          let counter = 0;
          const batchSize = 100;
          const blockchainTokensToBeClaimedIds = blockchainTokensIds.slice(tokensAlreadyClaimed);

          while (counter < blockchainTokensToBeClaimedIds.length) {
            const iterationsLeft = blockchainTokensToBeClaimedIds.length - counter;
            const currentBatchSize = iterationsLeft < batchSize ? iterationsLeft : batchSize;

            const currentBatchOfTokensToClaim = blockchainTokensToBeClaimedIds.slice(counter, counter + currentBatchSize);
            const [getSignatureError, signature] = await to(functions.httpsCallable('signBlockchainTransaction')({ investorAddress, currentBatchOfTokensToClaim }));

            if (getSignatureError) {
              trackBlockchainTransactionError(
                'claimBlockchainTokens',
                'Error signing the transaction.',
                investorId,
                '',
              );
              commit(MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_ERROR, 'Failed to sign the transaction. Please try again.');
              return;
            }

            const tx = await assetHandler.claimUnits(currentBatchOfTokensToClaim, signature!.data, { gasLimit: 3000000 });
            const txResult = await tx.wait();

            counter += currentBatchSize;

            if (txResult.status === 1) {
              const [updatePaymentBlockchainTokensClaimedError] = await to(functions.httpsCallable('paymentBlockchainTokensClaimed')({ paymentId, investmentId, currentBatchSize }));

              if (updatePaymentBlockchainTokensClaimedError) {
                trackBlockchainTransactionError(
                  'claimBlockchainTokens',
                  'Update Payment Blockchain Tokens Claimed failed',
                  investorId,
                  tx.hash,
                );
                commit(MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_ERROR, 'Transaction for claiming the tokens failed. Please try again.');
                return;
              }

              if (counter === blockchainTokensToBeClaimedIds.length) {
                commit(MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_SUCCESS);
              }
            } else if (txResult.status !== 1) {
              trackBlockchainTransactionError(
                'claimBlockchainTokens',
                'Something went wrong.',
                investorId,
                tx.hash,
              );
              commit(MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_ERROR, 'Transaction for claiming the tokens failed. Please try again.');
              return;
            }
          }
        } else {
          commit(MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_ERROR, 'Make sure you have metamask extension installed on your browser.');
        }
      } catch (e: any) {
        trackBlockchainTransactionError(
          'claimBlockchainTokens',
          e.message || 'Something went wrong.',
          investorId,
          e.transactionHash ? e.transactionHash : '',
        );
        commit(MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_ERROR, 'Transaction for claiming the tokens failed.');
      }
    },
  },
  getters: {},
};
