import { render, staticRenderFns } from "./Logo.html?vue&type=template&id=dfb41f7a&scoped=true&"
import script from "./Logo.ts?vue&type=script&lang=ts&"
export * from "./Logo.ts?vue&type=script&lang=ts&"
import style0 from "@/scss/components/logo/_logo.scss?vue&type=style&index=0&id=dfb41f7a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfb41f7a",
  null
  
)

export default component.exports