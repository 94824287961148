/**
 * Exporting all the variables depending if we are in develop or in a brand specific branch
 */

module.exports = {
  logo: 'logo.svg',
  logoalt: 'logo-white.svg',
  logoShare: 'bloqhouse.png',
  scssVariables: './src/scss/variables/brands/_bloqhouse.scss',
  name: 'Bloqhouse technologies',
  phone: '+31 20 21 01 225',
  email: 'info@bloqhouse.com',
  website: 'https://www.bloqhouse.com',
  glossary: true,
  langFileName: 'bloqhouse.ts',
  modules: {
    landing: false,
    identification: true, // setting this to false requires manual changes
  },
  intercom: false,
  requireContractAgreement: true,
  requireQuestionnaire: false,
  requireLegalBanner: false,
};

// module.exports = {
//   logo: 'pdao.svg',
//   logoalt: 'logo-white.svg',
//   logoShare: 'bloqhouse.png',
//   scssVariables: './src/scss/variables/brands/_bloqhouse.scss',
//   name: 'PropertyDao',
//   phone: '+31 20 21 01 225',
//   email: 'info@propertydao.com',
//   website: 'https://www.propertydaonft.com/',
//   glossary: true,
//   langFileName: 'bloqhouse.ts',
//   modules: {
//     landing: false,
//     identification: true, // setting this to false requires manual changes
//   },
//   intercom: false,
//   requireContractAgreement: true,
//   requireQuestionnaire: false,
//   requireLegalBanner: false,
// };
